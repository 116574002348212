<template>
    <div class="g10POSTranData">
        <div class="filter-box">
            <div class="filter-bnts ">
                <div class="inline-block" >
                    <div class="flex-box">
                        <div class="lable-txt">营业日期：</div>
                        <el-date-picker class="from-date"
                        :clearable="false"
                        :editable="false"
                        v-model="businessHours"
                        type="date"
                        placeholder="">
                        </el-date-picker>
                    </div>
                </div>
                <div class="inline-block" >
                    <div class="flex-box">
                        <div class="lable-txt">付款方式:</div>
                        <div class="from-input" style="width: 180px;">
                            <el-select v-model="paymentids" multiple collapse-tags  placeholder="请选择">
                                <el-option
                                v-for="item in payType"
                                :key="item.PAY_ID"
                                :label="item.PAY_NAME"
                                :value="item.PAY_ID">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="inline-block">
                    <div class="flex-box eat">
                        <div class="lable-txt">账单状态:</div>
                        <div class="from-input">
                            <el-select v-model="isMarkedBill" placeholder="所有">
                                <el-option label="所有" value=""></el-option>
                                <el-option label="已打标签" :value="true"></el-option>
                                <el-option label="未打标签" :value="false"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="inline-block" >
                    <div class="bnt-search" @click="loadData()">查询</div>
                </div>
                <div class="inline-block" >
                    <div class="bnt-label" @click="MarkingBills(1)">设置标签</div>
                </div>
                <div class="inline-block" >
                    <div class="bnt-label" @click="MarkingBills(2)">取消标签</div>
                </div>
                <div class="inline-block" >
                    <el-checkbox v-model="$global.isLableData" label="本站点默认隐藏标签数据" ></el-checkbox>
                </div>
                <div class="inline-block" v-if="isPlanShow">
                    <div class="bnt-label" @click="planIsShow=true">系统设置</div>
                </div>
            </div>
            
        </div>
        <div class="content-box clearfix">
            <div class="table-box grey-table isFooter" ref="tableBox">
                <el-table ref="elTable" class="el-table--scrollable-y"  :data="tableListPage" 
                 border 
                 show-summary
                :summary-method="getSummaries"
                @selection-change="(val)=>{multipleFoodsTable=val}"
                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" :default-sort = "{prop: 'date', order: 'descending'}">
                    <el-table-column type="selection"  :selectable="selectable" width="40" />
                    <el-table-column fixed prop="Rpt_Date" label="营业日期" min-width="90" :formatter="shortDateFormat"></el-table-column>
                    <el-table-column prop="Eat_CheckIndex" label="结账单号" min-width="140"></el-table-column>
                    <el-table-column fixed prop="Eat_DeskName" align="left" label="台号" min-width="70"></el-table-column>
                    <el-table-column fixed prop="Eat_StationName" align="left" label="收银站点" min-width="70"></el-table-column>
                    <el-table-column prop="Eat_CheckMoney" label="账单金额" align="right" min-width="80" :formatter="priceFormat"></el-table-column>
                    <el-table-column prop="Eat_CheckTime" label="结账时间" width="140" :formatter="dateFormat"></el-table-column>
                    <el-table-column prop="Eat_CheckCzy" label="结账人" min-width="100"></el-table-column>
                    <el-table-column prop="Order_PaymentDetailList" label="付款情况" align="right" min-width="100" >
                        <template #default="scope">
                            <el-tooltip  effect="dark" :content="paymentInfo(scope.row.Order_PaymentDetailList)" placement="top">
                                <div class="lefts">{{paymentInfo(scope.row.Order_PaymentDetailList)}}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Is_MarkedBill" label="标签" min-width="100">
                        <template #default="scope">
                            <span class="kdsNoShow">
                                <i class="iconfont icon-gou" v-if="scope.row.Is_MarkedBill"></i>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <table-page-btn class="fy" 
            :default-index="currentPage" 
            @current-change="(index)=>currentPage=index"
            :data="billFilter"
             @page-change="(pageList)=>tableListPage=pageList" 
             :page-size="pagesize"></table-page-btn>

        </div>
        <!-- 厨师设置 -->
        <modal-load :isShow="planIsShow" >
            <account-plan-model :isShow="planIsShow" @closeModel="planIsShow=false" ></account-plan-model>
        </modal-load>
     </div>
</template>
<script>

import accountPlanModel from './accountPlanModel/accountPlanModel.vue'
/**账单标签 */
export default {
    name:'g10POSTranData',
    components:{accountPlanModel},
    data(){
        return {
            currentPage:1,
            pagesize:9,//每页的数据条数
            userInfo:'',
            foodtableList:[],
            //分页显示数据
            tableListPage:[],
            /**营业时间 */
            businessHours:new Date(),
            payType:[], /**支付方式 */
            paymentids:[], /**选择的支付方式 */
            //点单状态
            isMarkedBill:"",
            //多选
            multipleFoodsTable:[],
            isPlanShow:false,
            planIsShow:false
        }
    },
    computed:{
        //操作的类型 根据勾选的数据判断
        multipeOp(){
            if(this.multipleFoodsTable.length>0){
                return this.multipleFoodsTable[0].Is_MarkedBill
            }
            return "";
        },
        /**过滤数据 */
        billFilter(){
            let list=this.foodtableList||[];
            if(typeof this.isMarkedBill=='boolean'){
                list=list.filter(it=>it.Is_MarkedBill==this.isMarkedBill)
            }
            return list;
        },
        /**合计数据 */
        summaryTableList(){
            let total={
                Eat_CheckMoney:0,
                money:0,
                num:this.billFilter?.length||"0"
            };//合计
            this.multipleFoodsTable?.forEach(it=>{
                total.money+=it.Eat_CheckMoney;
            })
            this.billFilter?.forEach((it)=>{
                total.Eat_CheckMoney+=it.Eat_CheckMoney;
            });
            return total;
        }
    },
    watch:{
        multipleFoodsTable(){
            if(typeof this.multipeOp=="boolean"){
                this.multipleFoodsTable?.forEach(it=>{
                    if(it.Is_MarkedBill!=this.multipeOp){
                        this.$refs.elTable?.toggleRowSelection(it,false);
                    }
                })
            }
        }
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
        let date=new Date();
        if(this.userInfo && this.userInfo.Rpt_Date){//营业日期
            date=new Date(this.userInfo.Rpt_Date);
        }
        date.setDate(date.getDate()-1)
        this.businessHours=date.Format('yyyy-MM-dd')
        /** 获取支付方式 */
        this.$cacheData.PayType().then((data)=>{
            data.map(item=>{
                item.PayTypes.map(pay=>{
                    this.payType.push(pay)
                })
            })
        }).catch(e=>{
            this.$alert("支付方式获取失败："+e.message, "提示", {confirmButtonText: "确定"});
        })

        this.$shortcutKey.setFun((e,KeyBoard)=>{
            if(KeyBoard=="Ctrl+Shift+Alt+S"){
                this.isPlanShow=true;
            }
        })
        
    },
    methods:{
        selectable(row){
            if(this.multipeOp==="") {
                return true;
            }else{
                return row.Is_MarkedBill===this.multipeOp;
            }
        },
        /**计算 一页显示多少条数据 */
        setTablePageSize(){
            this.$nextTick(()=>{
                if(this.$refs.tableBox){
                    let height=this.$refs.tableBox.clientHeight - 35 - 50;
                    this.pagesize=parseInt(height/50);
                }
            })
        },
        //设置行类名
        setClassName({ row, rowIndex }){
            if(row.isTotal){
                return "total";
            }else if(row.Eat_State==1){//1未结账  2已结账
                return "unclosed";
            }
            return '';
        },
        
         /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /*时间格式 */
        dateFormat(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm:ss');
        },
        /*时间格式 */
        shortDateFormat(row, column, cellValue){
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd');
        },
        //合计
        getSummaries({ columns, data }){
            const sums= [];
            let total=this.summaryTableList;
            columns.forEach((column, index) => {
                if(column.property=="Eat_CheckMoney"){
                    sums[index]=parseFloat(total[column.property].toFixed(2));
                }else if(column.property=="Eat_CheckIndex"){
                    sums[index]="标记金额:￥"+parseFloat(total.money.toFixed(2));
                }else{
                    sums[index]="";
                }
            })
            sums[0]="合计:";
            sums[1]=total.num+"笔";
            return sums;
        },
        /**付款情况 */
        paymentInfo(list){
            let str=[];
            if(list && list.length>0){                
                list.forEach(item => {
                    str.push(item.Payment_Name+":"+item.Payment_Money);
                });
            }
            return str.join(',');
        },
        /**查询 */
        loadData(){
            let param={
                Rpt_Date:(new Date(this.businessHours)).Format('yyyy-MM-dd'),
                User_ID:this.$auth.getUserID(),
                PaymentIds:this.paymentids
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            this.$httpAES.post("Bestech.CloudPos.GetBusinessBills",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.foodtableList=(d.ResponseBody||[]).filter(it=>it.Eat_State==2);//只显示已结账数据 ;
                    this.setTablePageSize();
                }else{
                    this.foodtableList=[];
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error(e.message);
                console.log(e);
            })
        },
        //账单标记 @pame Marking_Type 标记类型  1：打标签   2：取消标签
        MarkingBills(Marking_Type){
            
            if(Marking_Type==1 && this.multipeOp===true){
                this.$message.warning('请勾选未打标签的数据！');
                return;
            }

            if(Marking_Type==2 && this.multipeOp===false){
                this.$message.warning('请勾选已打标签的数据！');
                return;
            }
            let CheckIds=[];
            this.multipleFoodsTable?.forEach(it=>{
                CheckIds.push(it.Eat_CheckID)
            })

            if(CheckIds.length==0){
                this.$message.warning('请勾选要操作的数据！');
                return;
            }

            const loading = this.$loading({
                text: "数据操作中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            let Rpt_Date=(new Date(this.multipleFoodsTable[0].Rpt_Date)).Format('yyyy-MM-dd');
            
            this.$httpAES.post("Bestech.CloudPos.MarkingBills",{
                User_ID:userInfo.User_ID,
                Operate_User:userInfo?.Login_Name,
                Rpt_Date:Rpt_Date,
                CheckIds:CheckIds,
                Marking_Type:Marking_Type
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success((Marking_Type==1?"打标签":"取消标签")+"成功");
                    this.multipleFoodsTable?.forEach(it=>{
                        if(Marking_Type==1){
                            it.Is_MarkedBill=true;
                        }else{
                            it.Is_MarkedBill=false;
                        }
                    })
                    this.$refs.elTable?.clearSelection();
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error((Marking_Type==1?"打标签":"取消标签")+'失败：'+e.message);
                console.log('操作失败：',e);
            })
        },
    },
    unmounted(){
        this.$shortcutKey.setFun(()=>{})
    }
}
</script>

<style lang="scss" >
@import './g10POSTranData.scss'
</style>